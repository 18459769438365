// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

require('jquery');
require('slick-carousel');
require('fancybox');


//resize function
var defaultWidth = 1366, defaultFont = 16;
// var defaultHeight = 768, aspect = defaultWidth / defaultHeight;

var originalLocation = window.location;

function resize() {
  if($(window).width() >= 1600 ) defaultFont = 12;

  $('body').css('font-size', $('body').width() / defaultWidth * defaultFont + 'px');
};

$(window).on('resize', resize);

$(function() {

  resize();

  // form input file
  function fileInputChange(input) {
    var fileInput = $(input),
      label = fileInput.next('label'),
      labelSpan = label.find('span.name'),
      labelText = labelSpan.html();
    fileInput.on('change', function(el){
      var fileName = '';
      if(el.target.value) {
        fileName = el.target.value.split('\\').pop();
        if(fileName) {
          label.addClass('has-file');
          labelSpan.html(fileName);
        }
        else {
          label.removeClass('has-file');
          label.html(labelText);
        }
      }
    });
  }

  $('form input[type=file]').each(function(){
    fileInputChange(this);
  });



  //input type accent

  // var form = $('.form_wrap');


  // Animation

  $('.slider').slick({
    dots: false,
    infinite: true,
    speed: 500,
    fade: true,
    cssEase: 'linear'
  });



  //Scroll to ID
  var links = document.getElementsByClassName('toID');
  for(var i = 0; i < links.length; i++) {
    links[i].addEventListener('click', function(){
      var href = this.getAttribute('href');
      var target = document.querySelector(href);
      var pos = target.getBoundingClientRect().top + document.body.scrollTop;
      $('html, body').animate({scrollTop : pos}, 2000);
      return false;
    });
  }




  jQuery('.fancybox').fancybox({
    closeBtn : true
  });

  //Submit

  $('form').on('submit', function(e){
    e.preventDefault();
    var thisForm =  $(this);
    thisForm.find('input.referer').val(document.referer);
    thisForm.find('input.location').val(originalLocation);
    var resume = new FormData(thisForm[0]);

    //Validate text inputs
    var name = thisForm.find('input[name="name"]').val();
    var contacts = thisForm.find('input[name="contacts"]').val();

    //var exp = contacts.match('/^([_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,5}))|(\d+$)$/');
    var exp = new RegExp('^[а-яА-ЯёЁa-zA-Z0-9\-@_. ]+$');
    var validName = exp.test(name);
    //TODO: better regexp
    var validContacts = exp.test(contacts);

    if(!validName || !validContacts){
      console.log('Invalid text');
      jQuery.fancybox.open('<h5>Пожалуйста, укажите корректные данные</h5>');
      return false;
    }
    if(thisForm.find('input[type=file]:first').val() == ''){
      console.log('No file');
      jQuery.fancybox.open('<h5>Пожалуйста, выберите файл с резюме</h5>');
      return false;
    }

    //Send resume after validation
    $.ajax({
      url: '/vacancies.json',
      xhr: function() {
        var xhr = new window.XMLHttpRequest();
        xhr.upload.addEventListener("progress", function(evt) {
            if (evt.lengthComputable) {
                var percentComplete = evt.loaded / evt.total * 100;
                console.log("Upload:", percentComplete);
                thisForm.find('.p-bar').width(percentComplete + '%');
            }
       }, false);
       return xhr;
      },
      data : resume,
      type : 'POST',
      processData: false,
      contentType: false
    }).done(function(res){
      // if resume sent
      if(res.status == 'ok'){
        //yandex goal
        yaCounter40003100.reachGoal('resume_sent');
        //reset form
        thisForm[0].reset();
        thisForm.find('.file_label .name').html('прикрепить резюме');
      }
      jQuery.fancybox.open(res.html);
    }).fail(function(req, status, err){
      jQuery.fancybox.open('<h5>Не получилось отправить форму</h5><p>Проверьте, что вы подключены к интернету и попробуйте повторить попытку</p>');
    }).always(function(){
      thisForm.find('.p-bar').width('0');
    });
    return false;
  });


});
